<template>
  <div>
    <el-row type="flex" justify="center" v-if="banners.length > 0">
      <div style="width: 600px">
        <el-carousel height="200px" :interval="5000" arrow="always" v-loading="loading">
          <el-carousel-item v-for="(banner, indexBanner) in banners" :key="indexBanner">
            <img :src="banner.source_url" @click="onClickBanner(banner)" height="100%" width="100%" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-row>
    <header class="page-header">
      <div class="responsive" style="display:flex; justify-content:space-between; margin-top:2%;">
        <title-item title="Тайлан" />
        <div style="display:flex; flex-wrap:wrap; width:100%; justify-content: flex-end;">
          <el-select class="mr5" v-model="choosedOutletIds" collapse-tags multiple placeholder="Бүх харилцагч" style="width:250px;"
            @change="getAllReports">
            <el-option label="Бүх салбар" value="all"></el-option>
            <el-option v-for="(item, index) in outlets" :key="index" :label="item.full_name_eng" :value="item._id">
            </el-option>
          </el-select>
          <el-date-picker v-model="dateRanges" type="daterange" align="right" unlink-panels range-separator="-"
            style="width:250px;" start-placeholder="Эхлэх огноо" end-placeholder="Дуусах огноо"
            :picker-options="pickerOptions" class="dateTime mr5" @change="changeDate(dateRanges)">
          </el-date-picker>
          <el-tooltip class="item" effect="dark" content="Захиалгын түүх татах" placement="top">
            <el-button class="mr5" :loading="isExcelExportLoading" type="primary" style="width:250px;" @click="exportXLSX()">Тайлан
              татах</el-button>
          </el-tooltip>
        </div>
      </div>
    </header>
    <div style="width:100%; display:flex; flex-wrap:wrap;">
      <div class="kpiResponsive">
        <order-recieve-kpi-chart :outletIds.sync="choosedOutletIds"
          :dateRanges.sync="dateRanges"></order-recieve-kpi-chart>
      </div>
      <div class="reviewResponsive">
        <review-stat :outletIds.sync="choosedOutletIds" :dateRanges.sync="dateRanges">
        </review-stat>
      </div>
    </div>
    <div style="display:flex; flex-wrap: wrap; width:100%;">
      <div class="kpiResponsive">
        <top-products-chart :outletIds.sync="choosedOutletIds"></top-products-chart>
      </div>
      <div class="reviewResponsive">
        <complain-chart :outletIds.sync="choosedOutletIds" :dateRanges.sync="dateRanges">
        </complain-chart>
      </div>
    </div>
    <invoice-dialog v-if="visibleDialog" :show='visibleDialog' :close-function="closeFunction" />
  </div>
</template>

<script>
import axios from 'axios'
import OrderRecieveKpiChart from './components/OrderRecieveKpiChart'
import ComplainChart from './components/ComplainChart.vue'
import ReviewStat from './components/ReviewStat.vue'
import topProductsChart from './components/topProductsChart.vue'
import invoiceDialog from './components/invoiceDialog.vue'
import dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import mAxios from '../../../helper/axiosInstance'
import XLSX from 'xlsx'
import TitleItem from '../../../components/items/titleItem.vue'
dayjs.extend(utc)
dayjs.extend(timezone)
export default {
  name: 'Home',
  components: {
    OrderRecieveKpiChart,
    ComplainChart,
    ReviewStat,
    topProductsChart,
    invoiceDialog,
    TitleItem
  },
  data () {
    return {
      invoiceData: null,
      loading: false,
      banners: [],
      choosedOutletIds: [],
      choosedOutletNames: [],
      dateRange: null,
      dateRanges: [
        dayjs().add(-1, 'month').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD')
      ],
      dateValue: null,
      salesReportType: 'sales',
      salesChartLoading: false,
      salesReportData: null,
      visibleDialog: false,
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      outlets: [],
      isExcelExportLoading: false
    }
  },
  async created () {
    this.getActiveBanners()
    if (!this.$root.isAdmin) {
      if (this.generateDate(this.$root.outlet.contract_expire_at) && this.$root.outlet.contract_is_paid === false) {
        this.visibleDialog = true
      }
    }
    this.outlets = this.$root.chosenOutlets
    if (this.outlets.length > 0) {
      this.outlets.forEach(item => {
        this.choosedOutletIds.push(item._id)
      })
    }
    this.getAllReports()
  },
  methods: {
    exportXLSX () {
      this.isExcelExportLoading = true
      const payload = {
        outlet_ids: this.choosedOutletIds,
        start_date: dayjs(this.dateRanges[0]).format('YYYY-MM-DD'),
        end_date: dayjs(this.dateRanges[1]).format('YYYY-MM-DD')
      }
      mAxios.post('getOrderHistory', payload).then(response => {
        if (response.data.status === 'success') {
          const reportXlsx = XLSX.utils.json_to_sheet(response.data.data)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, reportXlsx, 'report')
          XLSX.writeFile(wb, this.$root.isAdmin ? this.$root.chosenOutlets[0].full_name_mon + '.xlsx' : this.$root.outlet.full_name_mon + '.xlsx')
        } else {
          this.$message.error({
            message: response.data.message,
            type: 'error'
          })
        }
        this.isExcelExportLoading = false
      })
    },
    generateDate (expiredDate) {
      let isShow = false
      const today = dayjs().tz('Asia/Ulaanbaatar').format('YYYY-MM-DD HH:mm:ss')
      const tempDate = dayjs(expiredDate).add(-7, 'day').format('YYYY-MM-DD HH:mm:ss')
      if (today > tempDate && today <= expiredDate) {
        isShow = true
      }
      return isShow
    },
    closeFunction () {
      this.visibleDialog = false
    },
    getAllReports () {
      if (this.choosedOutletIds.length === 0) {
        this.$notify({
          title: 'Алдаа',
          message: 'Харилцагч сонгоно уу',
          type: 'error',
          position: 'bottom-left'
        })
        return false
      }
      this.choosedOutletIds.filter(res => {
        if (res === 'all') {
          this.choosedOutletIds = []
          this.$root.chosenOutlets.map(outletRes => {
            this.choosedOutletIds.push(outletRes._id)
          })
        }
      })
      this.choosedOutletNames = this.outlets.map(item => {
        if (this.choosedOutletIds.find(el => el === item.outlet_id)) {
          return item.outlet_name
        }
      })
    },
    async getActiveBanners () {
      this.loading = true
      await axios
        .get(
          'https://rest.toktok.mn/client-api-test/no-auth/settlement/getActiveBanners'
        )
        .then(response => {
          if (response.data.status === 'success') {
            this.banners = response.data.data
            this.loading = false
          } else {
            this.loading = false
          }
        })
    },
    changeDate (dates) {
      const timeDiff = Math.abs(dates[0].getTime() - dates[1].getTime())
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
      if (diffDays > 91) {
        this.dateRanges = [
          dayjs().add(-1, 'month').format('YYYY-MM-DD'),
          dayjs().format('YYYY-MM-DD')
        ]
        return this.$notify.warning({
          title: 'Анхааруулга',
          duration: 3000,
          message: 'Та 3 сараас илүү хугацаа оруулах боломжгүй!!!'
        })
      }
    },
    onClickBanner (banner) {
      if (banner.type === 'webLinking') {
        window.open(banner.href_url, '_blank')
      }
    }
  }
}
</script>
<style scoped>
.panel {
  padding-bottom: 0;
}
.panel-item {
  padding: 15px 20px;
}
.panel-item header {
  margin-bottom: 0;
}
@media screen and (min-width: 600px) {
  .widResponsive {
    width: 48%;
    margin-left: 1%;
  }
  .responsive {
    margin-left: 1%;
    width: 100%;
  }
  .kpiResponsive {
    width: 60%;
    margin-left: 1%;
  }
  .reviewResponsive {
    width: 36%;
    margin-left: 1%;
  }
}
@media screen and (max-width: 600px) {
  .widResponsive {
    width: 100%;
  }
  .kpiResponsive {
    width: 100%;
  }
  .reviewResponsive {
    width: 100%;
  }
}
</style>
