<template>
  <div class="panel" v-loading="loading">
    <div style="padding: 10px; border-bottom: 1px solid #dfe3e8; display:flex; justify-content: space-between; align-items:center;">
      <header class="ml15 mt10">Гомдлын тайлан</header>
      <el-button style="width:25%;" size="mini" @click="download">
        <i class="el-icon-download"></i>
        Татах
      </el-button>
    </div>
    <div class="panel-item">
      <apexchart :options="salesChartOptions" :series="series" height="330" v-if="series"></apexchart>
    </div>
  </div>
</template>
<script>
import mAxios from '../../../../helper/axiosInstance'
import * as dayjs from 'dayjs'
import XLSX from 'xlsx'
export default {
  name: 'ComplainChart',
  props: [
    'outletIds',
    'dateRanges'
  ],
  data () {
    return {
      activeTab: 'sales',
      loading: false,
      salesChartOptions: {
        chart: {
          type: 'bar',
          height: 380
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + 'Ш'
            }
          }
        },
        xaxis: {
          categories: []
        }
      },
      series: [{
        name: 'Нийт',
        data: []
      }]
    }
  },
  mounted () {
    this.getCancelReport(this.outletIds, this.dateRanges)
  },
  watch: {
    dateRanges: function (newval, old) {
      this.getCancelReport(this.outletIds, newval)
    },
    outletIds: function (newval, old) {
      this.getCancelReport(newval, this.dateRanges)
    }
  },
  methods: {
    async download () {
      this.loading = true
      const payload = {
        outletIds: this.outletIds,
        start_date: dayjs(this.dateRanges[0]).format('YYYY-MM-DD'),
        end_date: dayjs(this.dateRanges[1]).format('YYYY-MM-DD')
      }
      await mAxios.post('complaint/getComplaintsDashboardByOutlet', payload).then(response => {
        if (response.data.status === 'success') {
          const reportXlsx = response.data.data.rows.length > 0 ? XLSX.utils.json_to_sheet(response.data.data.rows) : ['Гомдол байхгүй']
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, reportXlsx, 'report')
          XLSX.writeFile(wb, 'Гомдлын тайлан.xlsx')
          this.loading = false
        } else {
          this.$notify({
            title: 'Алдаа',
            message: response.data.message,
            type: 'error',
            position: 'bottom-left'
          })
          this.loading = false
        }
      })
    },
    async getCancelReport (outletIds = [], dateRanges = []) {
      if (outletIds.length === 0 || dateRanges.length === 0) {
        return false
      }
      this.loading = true
      const payload = {
        outletIds: outletIds,
        start_date: dayjs(dateRanges[0]).format('YYYY-MM-DD'),
        end_date: dayjs(dateRanges[1]).format('YYYY-MM-DD')
      }
      await mAxios.post('complaint/getComplaintsDashboardByOutlet', payload).then(response => {
        if (response.data.status === 'success') {
          this.series = [{
            data: []
          }]
          this.salesChartOptions = {
            chart: {
              type: 'bar',
              height: 380
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true
              }
            },
            dataLabels: {
              enabled: false
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val + 'Ш'
                }
              }
            },
            xaxis: {
              categories: []
            }
          }
          response.data.data.count.map(count => {
            this.salesChartOptions.xaxis.categories.push(['', null, undefined].includes(count.complaint_3) ? 'Бусад' : count.complaint_3)
            this.series[0].data.push(count.count)
          })
          this.loading = false
        } else {
          this.$notify({
            title: 'Алдаа',
            message: response.data.message,
            type: 'error',
            position: 'bottom-left'
          })
          this.loading = false
        }
      })
    }
  }
}
</script>
