<template>
  <div v-loading="loading">
    <div class="panel tabled">
      <div style="padding: 10px 10px 10px 10px; border-bottom: 1px solid #dfe3e8; display:flex; justify-content: space-between; align-items:center;">
        <span class="ml15" style="font-size: 16px;">KPI тайлан</span>
        <el-button size="mini" @click="download">
          <i class="el-icon-download"></i>
          Цуцлалтын тайлан татах
        </el-button>
      </div>
      <div
        class="panel-item"
        style="display:flex; flex-direction:row; flex-wrap:wrap; justify-content:space-between; padding-bottom: 10px;"
      >
        <el-card style="background-color:#F8F8F8">
          <header>Цуцлалт</header>
          <apexchart
            height="250"
            :options="cancelChartOptions"
            :series="cancelSeries"
          ></apexchart>
        </el-card>
        <el-card style="background-color:#F8F8F8">
          <header>Хугацаандаа бэлтгэсэн</header>
          <apexchart
            height="250"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import mAxios from '../../../../helper/axiosInstance'
import XLSX from 'xlsx'
export default {
  name: 'OrderRecieveKpiChart',
  props: ['outletIds', 'dateRanges'],
  data: function () {
    return {
      series: [0, 0],
      cancelSeries: [0, 0],
      loading: false,
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate (time) {
          return time.getTime() > Date.now()
        }
      },
      chartOptions: {
        chart: {
          type: 'donut',
          stacked: true
        },
        colors: ['#FFB500', '#1B74E4'],
        plotOptions: {
          pie: {
            donut: {
              size: '55px',
              labels: {
                show: true,
                name: {
                  show: true
                },
                value: {
                  show: true,
                  color: '#000000'
                },
                total: {
                  show: true,
                  label: 'Нийт',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return parseFloat(val, 10).toFixed(1) + '%'
          }
        },
        labels: ['Амжсан', 'Хоцорсон']
      },
      cancelChartOptions: {
        chart: {
          type: 'donut',
          stacked: true
        },
        colors: ['#FFB500', '#1B74E4'],
        plotOptions: {
          pie: {
            donut: {
              size: '55px',
              labels: {
                show: true,
                name: {
                  show: true
                },
                value: {
                  show: true,
                  color: '#000000'
                },
                total: {
                  show: true,
                  label: 'Нийт',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return parseFloat(val, 10).toFixed(1) + '%'
          }
        },
        labels: ['Амжилттай', 'Цуцалсан']
      },
      dateFormatTypes: {
        week: 'yyyy-WW дх долоо хоног'
      }
    }
  },
  created () {
    this.getKPI(this.outletIds)
  },
  watch: {
    outletIds: function (newval, old) {
      this.getKPI(newval)
    }
  },
  methods: {
    download () {
      if (this.outletIds.length === 0) {
        return false
      }
      this.loading = true
      const payload = {
        outlet_ids: this.outletIds,
        start_date: this.$helpers.formatDate(
          this.dateRanges[0],
          'YYYY-MM-DD'
        ),
        end_date: this.$helpers.formatDate(
          this.dateRanges[1],
          'YYYY-MM-DD'
        )
      }
      mAxios.post('getCanceledOrderData', payload).then(response => {
        if (response.data.status === 'success') {
          const reportXlsx = XLSX.utils.json_to_sheet(response.data.data)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, reportXlsx, 'report')
          XLSX.writeFile(wb, 'Цуцлалтын тайлан.xlsx')
          this.loading = false
        } else {
          this.$notify({
            title: 'Алдаа',
            message: response.data.message,
            type: 'error',
            position: 'bottom-left'
          })
          this.loading = false
        }
      })
    },
    changeDate () {
      this.getKPI(this.outletIds)
    },
    getKPI (outletIds = []) {
      if (outletIds.length === 0) {
        return false
      }
      this.loading = true
      const payload = {
        outlet_ids: outletIds,
        start_date: this.$helpers.formatDate(
          this.dateRanges[0],
          'YYYY-MM-DD'
        ),
        end_date: this.$helpers.formatDate(
          this.dateRanges[1],
          'YYYY-MM-DD'
        )
      }
      mAxios.post('getNewKpi', payload).then(response => {
        this.series = [0, 0]
        this.cancelSeries = [0, 0]
        if (response.data.status === 'success') {
          this.series = response.data.data.cancelOrdersDataSet.datasets.data
          this.cancelSeries = response.data.data.succeeOrdersDataSet.datasets.data
          this.cancelChartOptions.labels = response.data.data.cancelOrdersDataSet.labels
        } else {
          this.$notify({
            title: 'Алдаа',
            message: response.data.message,
            type: 'error',
            position: 'bottom-left'
          })
        }
        this.loading = false
      })
    }
  }
}
</script>
