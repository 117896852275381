<template>
  <el-dialog
    v-if="invoiceData !== null"
    title="Системийн төлбөрийн нэхэмжлэх"
    :visible.sync="show"
    width="40%"
    :before-close="closeFunction"
    center>
    <div v-for="(data, index) in invoiceData" :key="index" class="mt10" @click="handleClick(data)">
      <el-card class="cursor-pointer">
        <el-row type="flex" align="middle">
          <el-col :span="14">{{ data.outlet_name }}</el-col>
          <el-col :span="6">{{ data.pay_date }}</el-col>
          <el-col :span="4">
            <el-tag>{{ $helpers.formatPrice(data.pay_total) }}₮</el-tag>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import mAxios from '../../../../helper/axiosInstance'
export default {
  name: 'invoiceDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    closeFunction: {
      type: Function,
      default: () => false
    }
  },
  async created () {
    await this.getPartnerInvoice()
  },
  data () {
    return {
      invoiceData: null
    }
  },
  methods: {
    handleClick (data) {
      this.$router.push({ name: 'invoice', params: { id: data.outlet_id } })
    },
    async getPartnerInvoice () {
      const ids = []
      if (this.$root.outlet.child_outlets.length > 0) {
        await this.$root.outlet.child_outlets.map(el => ids.push(el.outlet_id))
      } else {
        ids.push(this.$root.outlet._id)
      }
      this.loadingInvoice = true
      const body = {}
      body.outlet_ids = ids
      mAxios.post('outlet/getPartnerInvoice', body).then(servicesResponse => {
        if (servicesResponse.data.status === 'success') {
          this.invoiceData = servicesResponse.data.data.outlets
          this.loadingInvoice = false
        } else {
          this.$message.error({
            title: 'Алдаа гарлаа',
            message: servicesResponse.data.message,
            type: 'error'
          })
          this.loadingInvoice = false
        }
      })
    }
  }
}
</script>

<style>
</style>
