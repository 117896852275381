<template>
  <div class="panel tabled" v-loading="loading">
    <div class="panel-item">
      <header>Top 10</header>
    </div>
      <apexchart type="bar" height="380" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import mAxios from '../../../../helper/axiosInstance'
export default {
  name: 'ComparedCHart',
  props: ['outletIds', 'dateType', 'orderType'],
  data () {
    return {
      loading: false,
      chartOptions: {
        chart: {
          type: 'bar',
          height: 380
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + 'Ш'
            }
          }
        },
        xaxis: {
          categories: []
        }
      },
      series: [{
        name: 'Нийт',
        data: []
      }],
      clientData: null
    }
  },
  mounted () {
    this.clientData = this.$root.outlet
    this.getComparedReport(this.outletIds)
  },
  watch: {
    outletIds: function (newval) {
      this.getComparedReport(newval)
    }
  },
  methods: {
    getComparedReport (outletIds = []) {
      this.loading = true
      const payload = {
        outlet_ids: outletIds
      }
      mAxios.post('getTopSelledProducts', payload).then(response => {
        this.loading = false
        this.series = [{
          data: []
        }]
        this.chartOptions = {
          chart: {
            type: 'bar',
            height: 380
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true
            }
          },
          dataLabels: {
            enabled: false
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + 'Ш'
              }
            }
          },
          xaxis: {
            categories: []
          }
        }
        response.data.data.map(data => {
          this.chartOptions.xaxis.categories.push(data.name)
          this.series[0].data.push(data.total_count)
        })
      })
    }
  }
}
</script>
