<template>
  <div>
    <div class="panel tabled" v-loading="loading">
      <div class="panel-item">
        <el-row type="flex" align="middle">
          <header>
            Үнэлгээ
          </header>
        </el-row>
      </div>
      <div style="text-align:center">
        <div style="background-color: #F8F8F8;" class="mb14">
          <el-carousel height="120px" v-if="currentOutlet" arrow="always" class="pt30" :autoplay="false">
            <el-carousel-item v-for="(item, itemIndex) in reviewType === 'good' ? currentOutlet.good : currentOutlet.bad" :key="itemIndex">
              <el-rate disabled v-model="item.star"></el-rate>
              <p>{{ item.product_name ? item.product_name : '-' }}</p>
            </el-carousel-item>
          </el-carousel>
          <el-radio-group v-model="reviewType">
            <el-radio label="good">Эерэг</el-radio>
            <el-radio label="bad">Сөрөг</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="panel-item">
        <el-row>
          <el-col :span="12">
            <div
              class="cursor-pointer text-primary"
              @click="outletReviewVisible = true"
            >
              Хэрэглэгчийн үнэлгээ
            </div>
          </el-col>
          <el-col :span="12" class="text-right">
            <i class="el-icon-star-on"></i> {{ avgStarOutlet }}
          </el-col>
        </el-row>
      </div>
      <div class="panel-item">
        <el-row>
          <el-col :span="12"
            ><div
              class="cursor-pointer text-primary"
              @click="driverReviewVisible = true"
            >
              Жолоочийн үнэлгээ
            </div></el-col
          >
          <el-col :span="12" class="text-right"
            ><i class="el-icon-star-on"></i> {{ avgStarDriver }}</el-col
          >
        </el-row>
      </div>
    </div>
    <el-drawer
      :visible.sync="outletReviewVisible"
      :size="size"
      title="Хэрэглэгчийн үнэлгээ"
      :before-close="handleClose"
    >
      <div v-if="currentOutlet" class="ml20" style="height: 90vh; overflow:auto;">
        <title-item title="Хэрэглэгчийн үнэлгээ"></title-item>
        <div style="margin-top:5%; display:flex; justify-content:space-between; align-items: center; margin-right:2%;">
          <span class="text-xl" style="font-size:30px;">
            <i class="el-icon-star-on" style="color: #FFB500; font-size: 4vh;"></i>
            <span style="font-size:36px; color: #1B74E4;">{{ currentOutlet.avg_star_user }}</span>
          </span>
          <el-checkbox v-model="allOutlets" @change="setCurrentOutlet">Бүх салбар</el-checkbox>
        </div>
        <div style="display:flex; margin-top:5%; margin-bottom:5%;">
          <el-select
            v-model="choosedOutlet"
            placeholder="Харилцагч сонгох"
            @change="setCurrentOutlet"
            class="mr5"
            style="width:70%;"
          >
            <el-option
              v-for="(item, index) in reviewOutlets.data"
              :key="index"
              :label="item.outlet_name"
              :value="item._id"
            >
            </el-option>
          </el-select>
          <el-button type="success" :loading="isExcelExportLoading" class="mb10" @click="exportXLSX">Татаж авах (.xlsx)</el-button>
        </div>
        <div style="border: 1px solid #E6E6E6; border-radius:20px; padding:0 1% 1px 1%; width:95%;">
          <h2 class="ml20">Эерэг сэтгэгдэл</h2>
          <hr style="border-top: 2px dashed #E6E6E6; width:90%;"/>
          <div v-for="(good, goodIndex) in currentOutlet.good" :key="goodIndex" style="background-color:#F8F8F8; margin:2% 5% 2% 5%; height:100%; border-radius:20px;">
            <div style="display:flex; justify-content:space-between; padding:4% 4% 0 4%;">
              <span>{{good.product_name}}</span>
              <el-rate v-model="good.star" disabled></el-rate>
            </div>
            <hr style="border: 2px dashed #E6E6E6; width:90%;"/>
            <p style="text-align:center; hyphens: auto; padding-left:10%; padding-right:10%; padding-bottom:3%;">{{good.desc ? good.desc : '-'}}</p>
          </div>
        </div>
        <div style="border: 1px solid #E6E6E6; border-radius:20px; padding:0 1% 1px 1%; width:95%; margin-top:1%;">
          <h2 class="ml20">Сөрөг сэтгэгдэл</h2>
          <hr style="border-top: 2px dashed #E6E6E6; width:90%;"/>
          <div v-for="(bad, badIndex) in currentOutlet.bad" :key="badIndex" style="background-color:#F8F8F8; margin:2% 5% 2% 5%; height:100%; border-radius:20px;">
            <div style="display:flex; justify-content:space-between; padding:4% 4% 0 4%;">
              <span>{{bad.product_name}}</span>
              <el-rate v-model="bad.star" disabled></el-rate>
            </div>
            <hr style="border: 2px dashed #E6E6E6; width:90%;"/>
            <p style="text-align:center; hyphens: auto; padding-left:10%; padding-right:10%; padding-bottom:3%;">{{bad.desc ? bad.desc : '-'}}</p>
          </div>
        </div>
        <el-collapse v-model="activeNames" style="margin-top:2%; width:97%;">
          <el-collapse-item name="1">
            <template slot="title">
              <h2 class="ml20">Өндөр үнэлгээтэй харилцагчид</h2>
            </template>
            <div style="border: 1px solid #E6E6E6; border-radius:20px; padding:0 1% 1px 1%; width:97%; margin-top:3px;">
              <div v-for="(highReview, highReviewIndex) in reviewOutlets.highReviewedOutlets" :key="highReviewIndex" style="background-color:#F8F8F8; margin:2% 5% 2% 5%; height:100%; border-radius:20px;">
                <div style="display:flex; justify-content:space-between; padding:4% 4% 4% 4%;">
                  <span :style="highReview.isOwn ? 'font-weight:bold; font-size:15px' : ''">
                    {{highReviewIndex + 1 }}
                    {{highReview.outlet_name}}</span>
                  <el-rate v-model="highReview.star" disabled></el-rate>
                </div>
              </div>
              <div v-if="isFound" style="text-align:center">
                <strong>...</strong>
              </div>
              <div v-if="isFound" style="background-color:#F8F8F8; margin:2% 5% 2% 5%; height:100%; border-radius:20px;">
                <div style="display:flex; justify-content:space-between; padding:4% 4% 4% 4%;">
                  <span style="font-weight:bold; font-size:15px;">{{notTopOutlet.outlet_name}}</span>
                  <el-rate v-model="notTopOutlet.star" disabled></el-rate>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="driverReviewVisible"
      :size="size"
      title="Жолоочийн үнэлгээ"
      :before-close="handleClose"
    >
      <div v-if="currentOutlet" class="ml20" style="height: 90vh; overflow:auto;">
        <title-item title="Жолоочийн үнэлгээ"></title-item>
        <div style="margin-top:5%;">
          <span class="text-xl" style="font-size:30px;">
            <i class="el-icon-star-on" style="color: #FFB500; font-size: 4vh;"></i>
            <span style="font-size:36px; color: #1B74E4;">{{ currentOutlet.avg_star_driver }}</span>
          </span>
        </div>
        <div style="display:flex; margin-top:5%; margin-bottom:5%;">
          <el-select
            v-model="choosedOutlet"
            @change="setCurrentOutlet"
            class="mr5"
            style="width:70%;"
          >
            <el-option
              v-for="(item, index) in reviewOutlets.data"
              :key="index"
              :label="item.outlet_name"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </div>
        <div style="border: 1px solid #E6E6E6; border-radius:20px; padding:0 1% 1px 1%; width:95%;">
          <h2 class="ml20">Жолоочийн сэтгэгдэл</h2>
          <hr style="border-top: 2px dashed #E6E6E6; width:90%;"/>
          <div v-for="(driver, driverIndex) in currentOutlet.from_driver" :key="driverIndex" style="background-color:#F8F8F8; margin:2% 5% 2% 5%; height:100%; border-radius:20px;">
            <div style="display:flex; justify-content:space-between; padding:4% 4% 0 4%;">
              <span>{{driver.product_name}}</span>
              <el-rate v-model="driver.star" disabled></el-rate>
            </div>
            <hr style="border: 2px dashed #E6E6E6; width:90%;"/>
            <p style="text-align:center; hyphens: auto; padding-left:10%; padding-right:10%;">{{driver.desc}}</p>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import mAxios from '../../../../helper/axiosInstance'
import XLSX from 'xlsx'
import dayjs from 'dayjs'
import titleItem from '../../../../components/items/titleItem.vue'
export default {
  components: { titleItem },
  name: 'ReviewStat',
  props: [
    'outletIds',
    'dateRanges'
  ],
  data () {
    return {
      outletReviewVisible: false,
      driverReviewVisible: false,
      loading: false,
      avgStarDriver: 0,
      avgStarOutlet: 0,
      allOutlets: false,
      reviewOutlets: [],
      currentOutlet: null,
      choosedOutlet: '',
      isExcelExportLoading: false,
      reviewDate: [],
      size: '',
      reviewType: 'good',
      activeNames: ['1'],
      allPartner: [],
      notTopOutlet: {},
      isFound: false
    }
  },
  mounted () {
    this.getReviews()
    if (window.innerWidth < 600) {
      this.size = '90%'
    } else if (window.innerWidth > 601 && window.innerWidth < 1200) {
      this.size = '60%'
    } else {
      this.size = '30%'
    }
  },
  methods: {
    exportXLSX () {
      this.isExcelExportLoading = true
      const payload = {
        outletIds: this.allOutlets ? this.allPartner : [this.choosedOutlet],
        start_date: this.dateRanges !== null && this.dateRanges.length > 0 ? this.dateRanges[0] : dayjs().format('YYYY-MM-DD HH:mm:ss'),
        end_date: this.dateRanges !== null && this.dateRanges.length > 0 ? this.dateRanges[1] : dayjs().format('YYYY-MM-DD HH:mm:ss')
      }
      mAxios.post('review/downloadReviews', payload).then(response => {
        if (response.data.status === 'success') {
          var reportXlsx = XLSX.utils.json_to_sheet(response.data.data)
          var wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, reportXlsx, 'report')
          XLSX.writeFile(wb, 'review.xlsx')
        } else {
          this.$message.error({
            message: response.data.message,
            type: 'error'
          })
        }
        this.isExcelExportLoading = false
      })
    },
    handleClose () {
      this.outletReviewVisible = false
      this.driverReviewVisible = false
    },
    setCurrentOutlet () {
      if (this.allOutlets) {
        this.allPartner = []
        this.$root.chosenOutlets.map(outletRes => {
          this.allPartner.push(outletRes._id)
        })
      }
      this.currentOutlet = this.reviewOutlets.data.filter(
        item => item._id === this.choosedOutlet
      )[0]
      this.reviewOutlets.highReviewedOutlets.map(item => {
        item.star = parseFloat(item.star)
        if (item._id === this.choosedOutlet) {
          item.isOwn = true
        } else {
          item.isOwn = false
        }
      })
      const found = this.reviewOutlets.highReviewedOutlets.find(el => el._id === this.choosedOutlet)
      if (!found) {
        this.isFound = true
        this.notTopOutlet.outlet_name = this.currentOutlet.outlet_name
        this.notTopOutlet.star = parseFloat(this.currentOutlet.avg_star_user)
      } else {
        this.isFound = false
      }
    },
    async getReviews () {
      this.loading = true
      await mAxios
        .post('review/getReviews', { outletIds: this.outletIds })
        .then(response => {
          if (response.data.status === 'success') {
            if (response.data.data.data.length > 0) {
              this.reviewOutlets = response.data.data
              this.reviewOutlets.data.map(item => {
                this.avgStarOutlet += parseFloat(item.avg_star_user)
                this.avgStarDriver += parseFloat(item.avg_star_driver)
              })
              this.reviewOutlets.highReviewedOutlets.map(item => {
                item.star = parseFloat(item.star)
                if (item._id === this.reviewOutlets.data[0]._id) {
                  item.isOwn = true
                }
              })
              const avgStar = this.avgStarOutlet / this.reviewOutlets.data.length
              this.avgStarOutlet = avgStar.toFixed(2)
              this.avgStarDriver = this.avgStarDriver / this.reviewOutlets.data.length
              this.currentOutlet = this.reviewOutlets.data[0]
              this.choosedOutlet = this.currentOutlet._id
              const found = this.reviewOutlets.highReviewedOutlets.find(el => el._id === this.choosedOutlet)
              if (!found) {
                this.isFound = true
                this.notTopOutlet.outlet_name = this.currentOutlet.outlet_name
                this.notTopOutlet.star = parseFloat(this.currentOutlet.avg_star_user)
              } else {
                this.isFound = false
              }
            }
          } else {
            this.$notify({
              title: 'Алдаа',
              message: response.data.message,
              type: 'error',
              position: 'bottom-left'
            })
          }
          this.loading = false
        })
    }
  }
}
</script>
<style scoped>
.panel {
  padding-bottom: 0;
}
.panel-item {
  padding: 15px 20px;
}
.panel-item header {
  margin-bottom: 0;
}
</style>
